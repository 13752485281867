import React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { heart, heartOutline, eye } from "ionicons/icons";
import DownloadButton from "./DownloadButton"; // Adjust the path as needed

interface ImageWithFavoriteProps {
  userId: string;
  image: string;
  isFavorited: boolean;
  onFavorite: (
    userId: string,
    shootId: string,
    mediaId: string,
    image: string
  ) => void;
  onUnfavorite: (
    userId: string,
    shootId: string,
    mediaId: string,
    image: string
  ) => void;
  shootId: string;
  mediaId: string;
  showZoomButton?: boolean;
  onViewImage?: (image: string) => void;
}

const ImageWithFavorite: React.FC<ImageWithFavoriteProps> = ({
  userId,
  image,
  isFavorited,
  onFavorite,
  onUnfavorite,
  shootId,
  mediaId,
  showZoomButton = false,
  onViewImage,
}) => {
  const handleFavoriteClick = () => {
    if (isFavorited) {
      onUnfavorite(userId, shootId, mediaId, image);
    } else {
      onFavorite(userId, shootId, mediaId, image);
    }
  };

  return (
    <div className="imageWithClose">
      <img src={image} alt={image} style={{ width: "100%" }} />
      <div
        className="flex-row"
        style={{
          marginBottom: 15,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {showZoomButton && onViewImage && (
          <IonButton
            className="view"
            size="small"
            color="dark"
            onClick={() => onViewImage(image)}
          >
            <IonIcon slot="icon-only" icon={eye} />
          </IonButton>
        )}
        <DownloadButton imageUrl={image} fileName={"photo.png"} />

        <IonButton
          className="favorite"
          size="small"
          color="light"
          onClick={handleFavoriteClick}
        >
          <IonIcon
            slot="icon-only"
            color={isFavorited ? "danger" : "dark"}
            icon={isFavorited ? heart : heartOutline}
          />
        </IonButton>
      </div>
    </div>
  );
};

export default ImageWithFavorite;
