import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchAllFavoritesGroupedByShootId,
  unFavoriteMedia,
} from "../redux/actions";
import { extractNumberAndExtension } from "../helpers/utils";
import ImageWithFavorite from "./dashboard/components/ImageWithFavorite"; // Import the reusable component
import {
  IonModal,
  IonSlides,
  IonSlide,
  IonButton,
  IonIcon,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonText,
  useIonToast,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import "./Favorites.css";
import { set } from "lodash";

interface Favorite {
  media: {
    url: string;
    _id: string;
  };
  shoot: {
    _id: string;
    address: {
      street: string;
    };
    events: {
      start: string;
    };
  };
  user: string;
  _id: string;
  type: string;
  url: string;
}

interface FavoritesPageProps {
  fetchAllFavoritesGroupedByShootId: (userId: string) => Promise<any>;
  unFavoriteMedia: (payload: any) => Promise<any>;
  auth: any;
  favoriteMediaList: Favorite[];
}

const Favorites: React.FC<FavoritesPageProps> = ({
  fetchAllFavoritesGroupedByShootId,
  unFavoriteMedia,
  auth,
  favoriteMediaList,
}) => {
  const [presentToast] = useIonToast();

  const [previewImage, setPreviewImage] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string>("");
  const [selectedMediaItem, setSelectedMediaItem] = useState<Favorite | null>(
    null
  );

  useEffect(() => {
    if (auth?.user?._id) {
      fetchAllFavoritesGroupedByShootId(auth?.user?._id);
    }
  }, [auth, fetchAllFavoritesGroupedByShootId]);

  const handleUnfavoriteItem = async (
    userId: string,
    shootId: string,
    mediaId: string,
    url: string
  ) => {
    const payload = { userId, mediaId, shootId, type: "photo", url };
    await unFavoriteMedia(payload);
    presentToast("Removed from favorites", 3000);
    setPreviewImage(false);

    // Fetch updated favorites
    fetchAllFavoritesGroupedByShootId(userId);
  };

  return (
    <IonPage id="favorites">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
          <IonTitle>My Favorites</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ padding: 20 }}>
          {favoriteMediaList.map((favorite, index) => (
            <div key={index} style={{ marginBottom: 20 }}>
              <h2>{favorite.shoot.address.street}</h2>
              <div className="flex-row">
                <IonText color="medium">
                  <p>
                    {new Date(favorite.shoot.events.start).toLocaleString()}
                  </p>
                </IonText>
                <IonText>
                  <p>{extractNumberAndExtension(favorite?.url)}</p>
                </IonText>
              </div>

              <ImageWithFavorite
                userId={auth.user._id}
                image={favorite.url}
                isFavorited={true} // Always true since it's a favorites list
                onFavorite={() => {}} // No favorite action
                onUnfavorite={(userId, shootId, mediaId, image) =>
                  handleUnfavoriteItem(userId, shootId, mediaId, image)
                }
                shootId={favorite.shoot._id}
                mediaId={favorite?.media?._id}
                showZoomButton={true}
                onViewImage={(image) => {
                  setPreviewImage(true);
                  setCurrentImage(image);
                  setSelectedMediaItem(favorite);
                }}
              />
            </div>
          ))}
          <IonModal
            isOpen={previewImage}
            onDidDismiss={() => setPreviewImage(false)}
          >
            <div>
              <IonSlides options={{ zoom: true }}>
                <IonSlide className="swiper-zoom-container">
                  <ImageWithFavorite
                    userId={auth?.user?._id}
                    image={currentImage}
                    isFavorited={true} // Always true since it's a favorites list
                    onFavorite={() => {}} // No favorite action
                    onUnfavorite={(userId, shootId, mediaId, image) => {
                      handleUnfavoriteItem(userId, shootId, mediaId, image);
                    }}
                    shootId={selectedMediaItem?.shoot?._id || ""}
                    mediaId={selectedMediaItem?.media?._id || ""}
                    showZoomButton={false} // No zoom button in the modal
                  />
                </IonSlide>
              </IonSlides>
              <div className="flex-row">
                <IonText>
                  <p>{extractNumberAndExtension(currentImage)}</p>
                </IonText>
                <IonButton
                  color="light"
                  onClick={() => setPreviewImage(false)}
                  size="small"
                >
                  <IonIcon slot="icon-only" icon={closeOutline} />
                  Close
                </IonButton>
              </div>
            </div>
          </IonModal>
        </div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  favoriteMediaList: state.favoritedMediaData, // Access favorite media from Redux state
});

export default connect(mapStateToProps, {
  fetchAllFavoritesGroupedByShootId,
  unFavoriteMedia,
})(Favorites);
