export const FETCH_PHOTOG_SHOOTS_SUCCESS = "FETCH_PHOTOG_SHOOTS_SUCCESS";
export const FETCH_PHOTOG_SHOOTS_FAIL = "FETCH_PHOTOG_SHOOTS_FAIL";
export const FETCH_SHOOT_SUCCESS = "FETCH_SHOOT_SUCCESS";
export const FETCH_SHOOT_FAIL = "FETCH_SHOOT_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAIL = "SOCIAL_LOGIN_FAIL";
export const USER_LOADED = "USER_LOADED";
export const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE";
export const COMPLETED_SHOOTS_STORED = "COMPLETED_SHOOTS_STORED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOAD_MORE_UPDATE = "LOAD_MORE_UPDATE";
export const SAVE_PAGE_OFFSET = "SAVE_PAGE_OFFSET";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const FETCH_DRIVING_DISTANCE = "FETCH_DRIVING_DISTANCE";
export const FETCH_SHOOT_NOTES = "FETCH_SHOOT_NOTES";
export const FETCH_CLIENT_NOTES = "FETCH_CLIENT_NOTES";
export const FETCH_CLIENT_NOTES_FAIL = "FETCH_CLIENT_NOTES_FAIL";
export const FETCH_SHOOT_INVOICES = "FETCH_SHOOT_INVOICES";
export const FETCH_SHOOT_NOTES_FAIL = "FETCH_SHOOT_NOTES_FAIL";
export const FETCH_SHOOT_INVOICES_FAIL = "FETCH_SHOOT_INVOICES_FAIL";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_FAIL = "CREATE_NOTE_FAIL";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAIL = "UPDATE_NOTE_FAIL";
export const DELETE_NOTE = "DELETE_NOTE";
export const UPDATE_SHOOT = "UPDATE_SHOOT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const UPDATE_PHOTOG_SHOOTS = "UPDATE_PHOTOG_SHOOTS";
export const UPDATE_SELECTED_SHOOT = "UPDATE_SELECTED_SHOOT";
export const UPDATE_SHOOT_NOTES = "UPDATE_SHOOT_NOTES";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAIL = "CREATE_ACTIVITY_FAIL";
export const FETCH_FAVORITES_GROUPED_BY_SHOOT_ID =
  "FETCH_FAVORITES_GROUPED_BY_SHOOT_ID";
export const FETCH_ALL_FAVORITES_GROUPED_BY_SHOOT_ID =
  "FETCH_ALL_FAVORITES_GROUPED_BY_SHOOT_ID";
export const IS_MEDIA_FAVORITED = "IS_MEDIA_FAVORITED";
export const FAVORITE_MEDIA = "FAVORITE_MEDIA";
export const UNFAVORITE_MEDIA = "UNFAVORITE_MEDIA";
export const GET_UPSELL_LEADERBOARD = "GET_UPSELL_LEADERBOARD";
