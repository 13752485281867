/**
 * Photo service codes used throughout the application
 */
export const PHOTO_CODES = [
  "e5",
  "Me5",
  "Ce5",
  "e8",
  "Ae8",
  "Me8",
  "Ce8",
  "e10",
  "Me10",
  "Ce10",
  "e15",
  "Me15",
  "Ce15",
  "Me20",
  "Ce20",
  "ie5",
  "Mie5",
  "Cie5",
  "ie10",
  "Mie10",
  "Cie10",
  "ie12",
  "Mie12",
  "Cie12",
  "ie15",
  "Mie15",
  "Cie15",
  "ie20",
  "Mie20",
  "Cie20",
  "ie25",
  "Mie25",
  "Cie25",
  "ie36",
  "Mie36",
  "Cie36",
  "ie42",
  "Mie42",
  "Cie42",
  "ie50",
  "Mie50",
  "Cie50",
  "ie75",
  "Mie75",
  "Cie75",
  "ie100",
  "Mie100",
  "Cie100",
  "Aie36",
  "Aie42",
  "Aie50",
  "Aie75",
  "Aie100",
  "av1",
  "5apv",
  "Mav1",
  "Cav1",
  "av3",
  "Mav3",
  "Cav3",
  "av5",
  "Mav5",
  "Cav5",
  "av10",
  "Mav10",
  "Cav10",
  "av20",
  "Mav20",
  "Cav20",
  "av30",
  "Mav30",
  "Cav30",
  "vAp",
  "MvAp",
  "CvAp",
  "vA",
  "MvA",
  "CvA",
  "AvA",
  "vWAp",
  "MvWAp",
  "CvWAp",
  "vWA",
  "MvWA",
  "CvWA",
  "vCAp",
  "vCAnp",
  "vCA",
  "MvCAp",
  "CvCAp",
  "CvCA",
  "vW",
  "MvW",
  "CvW",
  "AvW",
  "vC",
  "MvC",
  "CvC",
  "ae",
  "Mae",
  "Cae",
  "VR",
  "MVR",
  "CVR",
  "AVR",
  "T",
  "TC",
  "MT",
  "CT",
  "RF",
  "RSC",
  "RFC",
  "a25",
  "a50",
  "ad1",
  "ad2",
  "ad3",
  "ad4",
  "ad5",
  "ad6",
  "ad7",
  "ad8",
  "ad9",
  "ad10",
  "ad11",
  "ad12",
  "ad13",
  "ad14",
  "ad15",
  "ad25",
  "ad36",
  "ad42",
  "ad50",
  "Aav1",
  "Aav3",
  "Aav5",
  "Aav10",
  "Aav15",
  "Aav20",
  "Aav25",
  "Aav30",
  "a10vA",
  "SP",
  "MSP",
  "CSP",
  "SMV",
  "SMV30",
  "RSMV",
  "MSMV",
  "CSMV",
  "ASMV",
  "TRN1",
  "TRN2",
  "TRN3",
  "TRN4",
  "MTRN",
  "CTRN",
  "ATRN",
  "SAT",
  "MSAT",
  "CSAT",
  "ASAT",
  "DLX",
  "ADLX",
  "KA",
  "a10vA",
  "a15vA",
  "a20vA",
  "UC",
] as const;

export type PhotoCode = (typeof PHOTO_CODES)[number];

/**
 * Payment configuration constants
 */
export const PAYMENT_CONFIG = {
  COST_MULTIPLIER_FOR_DIST: 0.15,
  TRAVEL_STIPEND_AMOUNT: 5,
  PREV_BASE_PAY: 15,
  SQFT_COMPENSATION_THRESHOLD: 4000,
  SQFT_COMPENSATION_AMOUNT: 15,
} as const;

/**
 * Status constants
 */
export const STATUS = {
  TENTATIVE: "Tentative",
  SCHEDULED: "Scheduled",
  PROCESSING: "Processing",
  POSTPONE: "Postpone",
  RESCHEDULE: "Reschedule",
  COMPLETED: "Completed",
  TRAINING: "Training",
} as const;

export type Status = (typeof STATUS)[keyof typeof STATUS];

/**
 * API endpoints
 */
export const API = {
  BASE_URL: process.env.REACT_APP_API_URL || "http://localhost:3000",
  ENDPOINTS: {
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    PROFILE: "/profile",
    SHOOTS: "/shoots",
    PAYMENTS: "/payments",
  },
} as const;

/**
 * Local storage keys
 */
export const STORAGE_KEYS = {
  AUTH_TOKEN: "authToken",
  USER_PROFILE: "userProfile",
  PREFERENCES: "userPreferences",
} as const;

/**
 * Date format patterns
 */
export const DATE_FORMATS = {
  DISPLAY_DATE: "MMM D, YYYY",
  DISPLAY_TIME: "h:mm A",
  API_DATE: "YYYY-MM-DD",
  SHORT_DATE: "M/DD/YY",
  ISO_DATE: "YYYY-MM-DDTHH:mm:ss.SSSZ",
} as const;

/**
 * UI Constants
 */
export const UI = {
  COLORS: {
    PRIMARY: "#007bff",
    SECONDARY: "#6c757d",
    SUCCESS: "#28a745",
    DANGER: "#dc3545",
    WARNING: "#ffc107",
    INFO: "#17a2b8",
    LIGHT: "#f8f9fa",
    DARK: "#343a40",
  },
  BREAKPOINTS: {
    XS: 0,
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1200,
  },
} as const;
