/**
 * Formats a number as currency with 2 decimal places
 * @param amount The number to format as currency
 * @returns Formatted currency string (e.g. "$123.45")
 */
export const roundedCurrency = (amount: number): string => {
  return amount ? `$${amount.toFixed(2)}` : "$0.00";
};

/**
 * Rounds a number to specified decimal places
 * @param number The number to round
 * @param decimal Number of decimal places (default: 2)
 * @returns Rounded number
 */
export const roundOffNumber = (number: number, decimal: number = 2): number => {
  return Math.round(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
};

/**
 * Formats telephone number for SMS by adding country code
 * @param telNum Phone number string
 * @returns Formatted phone number with country code
 */
export const formatTelForSms = (telNum: string): string | undefined => {
  const formatted = telNum?.replace(/\D/g, "");
  if (!formatted) return undefined;

  if (formatted.length === 10) {
    return `+1${formatted}`;
  } else if (formatted.length === 11) {
    return `+${formatted}`;
  }
  return formatted;
};

/**
 * Extracts number and extension from URL
 * @param url URL string containing number and extension
 * @returns Extracted number and extension (e.g. "123.jpg") or null if not found
 */
export const extractNumberAndExtension = (url: string): string | null => {
  const match = url.match(/_(\d+)\.(\w+)$/);
  if (match) {
    const [, number, extension] = match;
    return `${number}.${extension}`;
  }
  return null;
};

/**
 * Formats a file size in bytes to human readable format
 * @param bytes File size in bytes
 * @returns Formatted file size (e.g. "1.5 MB")
 */
export const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

/**
 * Formats an address object into a single string
 * @param address Address object with street, city, state and zip
 * @returns Formatted address string
 */
export const formatAddress = (address: {
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
}): string => {
  const parts = [
    address.street,
    address.city,
    address.state,
    address.zip,
  ].filter(Boolean);

  return parts.join(", ");
};
