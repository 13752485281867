import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "./PayBreakdown.css";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonText,
} from "@ionic/react";
import {
  arrowBackOutline,
  arrowForwardOutline,
  todayOutline,
} from "ionicons/icons";
import {
  fetchPhotographerShoots,
  fetchDistanceArrayAndTotal,
} from "../redux/actions";
import Accordion from "./Accordion";
import LabelDetail from "./LabelDetail";
import { roundedCurrency, roundOffNumber } from "../helpers/formatUtils";
import { PHOTO_CODES } from "../helpers/constants";
import {
  getBasePay,
  isPastEffectiveDate,
  PaymentConfig,
  Shoot,
} from "../helpers/payCalculations";

interface PayProps {
  fetchPhotographerShoots: (photographerId: string, filters: string) => void;
  fetchDistanceArrayAndTotal: (
    photographerId: string,
    coordinates: string[]
  ) => Promise<any>;
  photographerShoots: Shoot[];
  auth: {
    profile?: {
      _id: string;
      base_pay?: number;
      pay_multiplier?: number;
      geometry?: {
        coordinates: [number, number];
      };
    };
  };
}

const PayBreakdown: React.FC<PayProps> = ({
  photographerShoots,
  fetchPhotographerShoots,
  fetchDistanceArrayAndTotal,
  auth,
}) => {
  const start = moment(moment(), "YYYY/MM/DD")
    .startOf("week")
    .subtract(2, "days");
  const end = moment().endOf("week").subtract(1, "days");

  const [time, setTime] = useState({
    start,
    end,
  });

  const [grandTotal, setGrandTotal] = useState(0);
  const [distTravFns, setDistTravFns] = useState<Record<string, number>>({});
  const [weeklyDistance, setWeeklyDistance] = useState(0);

  const paymentConfig: PaymentConfig = {
    costMultiplierForDist: 0.15,
    travelStipendAmount: 5,
    prevBasePay: 15,
    cutOffDate: moment("2023-03-03"),
    sqftCompensationEffectiveDate: moment("2024-09-20"),
    payEffectiveDate: "2022-04-04",
  };

  // Fetch photographer shoots when time changes
  useEffect(() => {
    const photographerId = auth?.profile?._id;
    if (!photographerId) return;

    const startDate = moment(time.start).format("YYYY-MM-DD");
    const endDate = moment(time.end).format("YYYY-MM-DD");
    const filters = `startDate=${startDate}&endDate=${endDate}&status=Completed:Processing:Scheduled:Reschedule:Training`;

    fetchPhotographerShoots(photographerId, filters);
    setDistTravFns({});
    setWeeklyDistance(0);
    setGrandTotal(0);
  }, [auth?.profile?._id, time, fetchPhotographerShoots]);

  // Calculate totals and distances
  useEffect(() => {
    if (!photographerShoots?.length || !auth?.profile?.geometry?.coordinates) {
      return;
    }

    // Calculate initial grand total without distances
    let total = 0;
    photographerShoots.forEach((shoot) => {
      const dateComparison = isPastEffectiveDate(shoot);
      const invoices = shoot.invoice;

      total +=
        invoices[0].photographer_total_pay +
        (shoot.status !== "Reschedule" && shoot.status !== "Training"
          ? roundOffNumber(
              getBasePay(
                shoot.events.start,
                shoot.status,
                auth?.profile?.base_pay || 0,
                auth?.profile?.pay_multiplier || 1,
                paymentConfig
              )
            )
          : 0) +
        (dateComparison && shoot.status === "Completed"
          ? paymentConfig.travelStipendAmount
          : 0);
    });

    setGrandTotal(total);

    // Calculate distances and update totals
    if (!auth.profile?.geometry?.coordinates) return;

    const [lng, lat] = auth.profile.geometry.coordinates;
    const homeAddress = `${lat},${lng}`;
    const newDistances: Record<string, number> = {};

    // Group and sort shoots by date
    const shootsByDate = photographerShoots
      .filter(
        (shoot) =>
          shoot.status === "Completed" ||
          shoot.status === "Processing" ||
          shoot.status === "Reschedule" ||
          shoot.status === "Training" ||
          shoot.status === "Tentative" ||
          shoot.status === "Scheduled"
      )
      .sort((a, b) => {
        if (a?.events?.start && b?.events?.start) {
          return new Date(a.events.start) >= new Date(b.events.start) ? 1 : -1;
        }
        return 0;
      })
      .reduce((acc, shoot) => {
        const dateKey = moment(shoot.events.start).format("DD-MM-YYYY");
        return {
          ...acc,
          [dateKey]: (acc[dateKey] || []).concat(shoot),
        };
      }, {} as Record<string, Shoot[]>);

    // Calculate distances for each day
    Object.keys(shootsByDate).forEach((date) => {
      const dateWiseShoots = shootsByDate[date];
      const queryList = [
        homeAddress,
        ...dateWiseShoots.map(
          (shoot) =>
            `${shoot.address?.geometry?.coordinates[1]},${shoot.address?.geometry?.coordinates[0]}`
        ),
      ];

      for (let i = 0; i < queryList.length - 1; i++) {
        (async () => {
          if (!auth.profile?._id) return;

          let res = await fetchDistanceArrayAndTotal(auth.profile._id, [
            queryList[i],
            queryList[i + 1],
          ]);

          if (i === queryList.length - 2) {
            const tempRes = await fetchDistanceArrayAndTotal(auth.profile._id, [
              queryList[0],
              queryList[queryList.length - 1],
            ]);
            if (tempRes.success) {
              // Keep decimal places for distance calculation
              res.data.data = res.data.data + tempRes.data.data;
            }
          }

          if (
            res.success &&
            dateWiseShoots[i] &&
            isPastEffectiveDate(dateWiseShoots[i])
          ) {
            // Store the exact distance value
            newDistances[dateWiseShoots[i]._id] = res.data.data;

            // Update weekly distance total
            setWeeklyDistance((prev) => prev + res.data.data);

            // Calculate compensation with original rounding behavior
            const compensation = roundOffNumber(
              res.data.data * paymentConfig.costMultiplierForDist
            );

            // Update grand total
            setGrandTotal((prev) => prev + compensation);
          }
        })();

        if (dateWiseShoots[i]?._id) {
          newDistances[dateWiseShoots[i]._id] = 0;
        }
      }
    });

    setDistTravFns(newDistances);
  }, [photographerShoots, auth?.profile]);

  const dateHandlerNext = () => {
    setTime({
      end: moment(time.end).add(7, "days"),
      start: moment(time.start).add(7, "days"),
    });
  };

  const dateHandlerPrev = () => {
    setTime({
      start: moment(time.start).subtract(7, "days"),
      end: moment(time.end).subtract(7, "days"),
    });
  };

  const renderProducts = (shoot: Shoot) => {
    const invoices = shoot.invoice;
    const data = [];
    let distanceAmount = distTravFns[shoot._id];
    const dateComparison = isPastEffectiveDate(shoot);

    const shootDate = moment(shoot?.events?.start);
    const sqftCompensation =
      shootDate.isSameOrAfter(paymentConfig.sqftCompensationEffectiveDate) &&
      shoot.booking_form?.sqft &&
      shoot.booking_form.sqft >= 4000
        ? 15
        : 0;

    for (let i = 0; i < invoices?.length; i++) {
      for (let j = 0; j < invoices[i].products.length; j++) {
        data.push(invoices[i].products[j]);
      }
    }

    return (
      <>
        {shoot?.status !== "Reschedule" && shoot.status !== "Training" && (
          <div style={{ width: "100%" }}>
            <LabelDetail
              label="Base"
              detail={getBasePay(
                shoot?.events?.start,
                shoot?.status,
                auth?.profile?.base_pay || 0,
                auth?.profile?.pay_multiplier || 1,
                paymentConfig
              ).toFixed(2)}
            />
          </div>
        )}
        <div style={{ width: "100%" }}>
          {data.map(
            (product) =>
              product?.photographer_pay != null &&
              PHOTO_CODES.includes(product.code as any) &&
              product?.photographer_pay > 0 && (
                <div key={product._id}>
                  <LabelDetail
                    label={product?.description}
                    detail={product?.photographer_pay.toFixed(2)}
                  />
                </div>
              )
          )}
        </div>
        {distanceAmount !== undefined &&
        isPastEffectiveDate(shoot) &&
        shoot?.status !== "Postpone" ? (
          <div>
            <LabelDetail
              label={`Variable Trip Charge (${distanceAmount.toFixed(
                1
              )} miles)`}
              detail={`${roundOffNumber(
                distanceAmount * paymentConfig.costMultiplierForDist
              ).toFixed(2)}`}
            />
          </div>
        ) : null}

        {sqftCompensation > 0 && (
          <div>
            <LabelDetail label="4,000 sqft+ Compensation" detail="15.00" />
          </div>
        )}

        {dateComparison ? (
          <div>
            <LabelDetail
              label="Driving"
              detail={(
                Math.round(paymentConfig.travelStipendAmount * 100) / 100
              ).toFixed(2)}
            />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="profile-pay">
      <div className="section-title">Pay Breakdown</div>
      <div>
        <IonGrid>
          <div className="calendar-nav">
            <div>
              <IonButton
                onClick={dateHandlerPrev}
                color="light"
                size="small"
                fill="outline"
              >
                <IonIcon slot="icon-only" icon={arrowBackOutline} />
              </IonButton>
            </div>
            <div className="pay-week">
              {moment(time.start).format("M/DD/YY")}-
              {moment(time.end).subtract(1, "days").format("M/DD/YY")}
            </div>
            <div>
              <IonButton
                onClick={dateHandlerNext}
                color="light"
                size="small"
                fill="outline"
              >
                <IonIcon slot="icon-only" icon={arrowForwardOutline} />
              </IonButton>
            </div>
          </div>
          <IonRow>
            {photographerShoots.map((shoot: Shoot) => (
              <IonCol size="12" key={shoot._id}>
                <Accordion
                  title={
                    <div className="pay-title">
                      <span className="pay-day">
                        {moment(shoot?.events?.start).format("ddd, MM/DD")}
                      </span>{" "}
                      <span className="pay-hsn">HS# {shoot?.hsn}</span>
                      <span className="pay-amount">
                        {roundedCurrency(
                          (shoot.invoice[0].photographer_total_pay || 0) +
                            (shoot.status !== "Reschedule" &&
                            shoot.status !== "Training"
                              ? roundOffNumber(
                                  getBasePay(
                                    shoot.events.start,
                                    shoot.status,
                                    auth?.profile?.base_pay || 0,
                                    auth?.profile?.pay_multiplier || 1,
                                    paymentConfig
                                  )
                                )
                              : 0) +
                            (isPastEffectiveDate(shoot) &&
                            shoot.status === "Completed"
                              ? paymentConfig.travelStipendAmount
                              : 0) +
                            (distTravFns[shoot._id] !== undefined
                              ? roundOffNumber(
                                  distTravFns[shoot._id] *
                                    paymentConfig.costMultiplierForDist
                                )
                              : 0)
                        )}
                      </span>
                    </div>
                  }
                  content={
                    <div className="pay-content">
                      <div className="pay-content-title">
                        <span>
                          {shoot?.address?.street?.replace("null", "")}
                        </span>
                        <span style={{ float: "right", marginRight: 5 }}>
                          {`${shoot.client_name_first} ${shoot.client_name_last}`}
                        </span>
                      </div>
                      {renderProducts(shoot)}
                    </div>
                  }
                  icon={<IonIcon icon={todayOutline} />}
                  expand={false}
                />
              </IonCol>
            ))}
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <div style={{ padding: 10, textAlign: "center" }}>
                <span style={{ fontSize: 14 }}>Week Total Distance: </span>
                <IonText color="primary">
                  <strong>{weeklyDistance.toFixed(1)}</strong>
                </IonText>{" "}
                miles
              </div>
            </IonCol>
            <IonCol size="12">
              <div style={{ padding: 10, textAlign: "center" }}>
                <span style={{ fontSize: 16 }}>Week Total: </span>
                <span className="week-total">
                  ${roundOffNumber(grandTotal)}
                </span>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    photographerShoots: state.photographerShoots,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  fetchPhotographerShoots,
  fetchDistanceArrayAndTotal,
})(PayBreakdown);
