import React from "react";
import { IonButton, IonIcon } from "@ionic/react";
import { downloadOutline } from "ionicons/icons";

const DownloadButton = ({ imageUrl, fileName = "photo.png" }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <IonButton onClick={handleDownload} size="small" color="dark">
      <IonIcon slot="icon-only" icon={downloadOutline} />
    </IonButton>
  );
};

export default DownloadButton;
