import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  IonContent,
  IonToast,
  IonPage,
  IonItem,
  IonInput,
  IonButton,
  IonLabel,
} from "@ionic/react";

import { loginUser, loadCurrentUser } from "../redux/actions";
import "./Login.css";
import logo from "../assets/images/hs_logo_on_dark.png";

interface loginProps {
  loginUser: any;
  loadCurrentUser: any;
  loading: boolean;
  authenticated: boolean;
  auth: any;
  error: any;
  history: any;
}

const Login: React.FC<loginProps> = ({
  history,
  authenticated,
  loginUser,
  loadCurrentUser,
  auth,
}) => {
  useEffect(() => {
    if (authenticated) {
      history.push("/dashboard");
    }
  }, [authenticated, auth]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [touched, setTouched] = useState({ email: false, password: false });

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  };

  const handleBlur = (field: "email" | "password") => {
    setTouched((prev) => ({ ...prev, [field]: true }));
  };

  const getInputError = (field: "email" | "password") => {
    if (!touched[field]) return undefined;

    if (field === "email") {
      if (!email.trim()) return "Email is required";
      if (!validateEmail(email)) return "Please enter a valid email";
    }

    if (field === "password") {
      if (!password) return "Password is required";
    }

    return undefined;
  };

  const handleSubmit = async () => {
    setTouched({ email: true, password: true });

    if (!email.trim() || !validateEmail(email)) {
      setErrorMessage("Please enter a valid email address");
      setShowToast(true);
      return;
    }

    if (!password) {
      setErrorMessage("Password is required");
      setShowToast(true);
      return;
    }

    const res = await loginUser(email, password);
    if (res.status !== 200) {
      setErrorMessage(res.data);
      setShowToast(true);
      return;
    }
    loadCurrentUser();
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="login-wrapper">
          <div className="logo">
            <img src={logo} alt="HomeSnappers" />
          </div>
          <div className="login-card">
            <div className="input-group">
              <IonItem
                className={
                  touched.email && getInputError("email") ? "ion-invalid" : ""
                }
              >
                <IonLabel position="floating">Email</IonLabel>
                <IonInput
                  type="email"
                  value={email}
                  onIonChange={(e: CustomEvent) =>
                    setEmail(e.detail.value || "")
                  }
                  onIonBlur={() => handleBlur("email")}
                  required
                  autocomplete="email"
                />
              </IonItem>
              <IonItem
                className={
                  touched.password && getInputError("password")
                    ? "ion-invalid"
                    : ""
                }
              >
                <IonLabel position="floating">Password</IonLabel>
                <IonInput
                  type="password"
                  value={password}
                  onIonChange={(e: CustomEvent) =>
                    setPassword(e.detail.value || "")
                  }
                  onIonBlur={() => handleBlur("password")}
                  required
                  autocomplete="current-password"
                />
              </IonItem>
              <div className="actions">
                <IonButton
                  fill="solid"
                  color="primary"
                  expand="block"
                  onClick={handleSubmit}
                >
                  Sign In
                </IonButton>

                <a href="/forget-password">
                  <p>Forgot your password?</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={errorMessage}
        duration={3000}
      />
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  authenticated: state.authenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  loginUser,
  loadCurrentUser,
})(Login);
