import React from "react";
import {
  IonList,
  IonItem,
  IonLabel,
  IonContent,
  IonAvatar,
  IonIcon,
  IonText,
} from "@ionic/react";
import { trophyOutline } from "ionicons/icons";

interface Photographer {
  photographer_name: string;
  total_earned: number;
}

interface LeaderboardProps {
  upsellLeaderboard: Photographer[];
}

const Leaderboard: React.FC<LeaderboardProps> = ({ upsellLeaderboard }) => {
  return (
    <>
      <div className="section-title">Upsell Leaderboard</div>
      <IonList>
        {upsellLeaderboard?.map((photographer, index) => (
          <IonItem key={index}>
            <IonAvatar slot="start">
              <IonText
                style={{
                  fontSize: "large",
                  fontWeight: "bold",
                  color: index === 0 ? "gold" : "gray",
                }}
              >
                {index + 1}
              </IonText>
            </IonAvatar>
            <IonLabel>
              <h2>
                {photographer.photographer_name}
                {index === 0 && (
                  <IonIcon
                    icon={trophyOutline}
                    style={{ color: "gold", marginLeft: "8px" }}
                  />
                )}
              </h2>
              <p>Total Earnings: ${photographer.total_earned.toFixed(2)}</p>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

export default Leaderboard;
