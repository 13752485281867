/**
 * Status types for shoots/events
 */
export type ShootStatus =
  | "Tentative"
  | "Scheduled"
  | "Processing"
  | "Postpone"
  | "Reschedule"
  | "Completed"
  | "Training";

/**
 * Color types for UI elements
 */
export type ColorType =
  | "dark"
  | "success"
  | "secondary"
  | "tertiary"
  | "light"
  | "danger";

/**
 * Returns color based on shoot status
 * @param status The status of the shoot
 * @returns Color identifier for UI elements
 */
export const chipColor = (status: ShootStatus): ColorType => {
  switch (status) {
    case "Tentative":
      return "dark";
    case "Scheduled":
      return "success";
    case "Processing":
      return "secondary";
    case "Postpone":
      return "tertiary";
    case "Reschedule":
      return "light";
    default:
      return "danger";
  }
};

/**
 * Determines if a value is empty (null, undefined, empty string, or empty array)
 * @param value Value to check
 * @returns True if the value is empty
 */
export const isEmpty = (value: any): boolean => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "string" && value.trim().length === 0) ||
    (Array.isArray(value) && value.length === 0)
  );
};

/**
 * Truncates text to a specified length and adds ellipsis
 * @param text Text to truncate
 * @param maxLength Maximum length before truncation
 * @returns Truncated text with ellipsis if needed
 */
export const truncateText = (text: string, maxLength: number): string => {
  if (!text || text.length <= maxLength) return text;
  return `${text.slice(0, maxLength)}...`;
};

/**
 * Generates initials from a name
 * @param firstName First name
 * @param lastName Last name
 * @returns Initials (e.g. "JD" for "John Doe")
 */
export const getInitials = (firstName?: string, lastName?: string): string => {
  const first = firstName?.charAt(0) || "";
  const last = lastName?.charAt(0) || "";
  return (first + last).toUpperCase();
};

/**
 * Formats a status string for display
 * Capitalizes first letter and adds any necessary styling classes
 * @param status Status string
 * @returns Formatted status object with text and className
 */
export const formatStatus = (
  status: string
): { text: string; className: string } => {
  const formattedText =
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  const className = `status-${status.toLowerCase()}`;
  return { text: formattedText, className };
};
