import React from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAccordion,
  IonAccordionGroup,
  IonLabel,
  IonIcon,
  IonItem,
  IonBackButton,
  IonButtons,
  IonText,
} from "@ionic/react";
import "./Help.css";
import { callOutline, mailOutline } from "ionicons/icons";

interface helpProps {
  loading: boolean;
}

const Help: React.FC<helpProps> = ({ loading }) => {
  const renderAccordionItem = (
    description: string,
    price1: number,
    price2: number,
    price3: number
  ) => {
    return (
      <div className="help-item-wrapper">
        <div>
          <h4>{description}</h4>
        </div>
        {price1 > 0 && (
          <div className="pricing residential">{`$${price1}`}</div>
        )}
        {price2 > 0 && (
          <div className="pricing multifamily">{`$${price2}`}</div>
        )}
        {price3 > 0 && <div className="pricing commercial">{`$${price3}`}</div>}
      </div>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="dashboard" />
          </IonButtons>
          <IonTitle>Help</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="help">
          <div className="ion-padding">
            Key: <span className="residential key-item">Residential</span>{" "}
            <span className="multifamily key-item">Multi-family</span>
            <span className="commercial key-item">Commercial</span>{" "}
          </div>
          <IonAccordionGroup>
            <IonAccordion value="photo-packages">
              <IonItem slot="header" color="light">
                <IonLabel>Interior/Exterior Pricing</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem("15 Photos", 150, 274, 324)}
                {renderAccordionItem("25 Photos", 184, 304, 354)}
                {renderAccordionItem("36 Photos", 219, 329, 379)}
                {renderAccordionItem("42 Photos", 239, 349, 399)}
                {renderAccordionItem("50 Photos", 254, 364, 414)}
                {renderAccordionItem("Amenities", 79, 209, 259)}
                {renderAccordionItem(
                  "*Deluxe Photo Package (only Residential)",
                  99,
                  0,
                  0
                )}
                <IonText color="medium">
                  <sub>*$500,000+, or 3,500+ sqft. Min. 25 photos </sub>
                </IonText>
              </div>
            </IonAccordion>
            <IonAccordion value="video-packages">
              <IonItem slot="header" color="light">
                <IonLabel>Video Package Pricing</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem("Walkthrough", 324, 474, 474)}
                {renderAccordionItem("Cinematic", 504, 624, 624)}
                {renderAccordionItem(
                  "Walkthrough + Aerial (photo)",
                  374,
                  674,
                  724
                )}
                {renderAccordionItem(
                  "Walkthrough + Aerial (no photo)",
                  424,
                  724,
                  774
                )}
                {renderAccordionItem(
                  "Cinematic + Aerial (photo)",
                  574,
                  824,
                  874
                )}
                {renderAccordionItem(
                  "Cinematic + Aerial (no photo)",
                  624,
                  874,
                  924
                )}
                {renderAccordionItem(
                  "60-sec Social Media Video",
                  154,
                  284,
                  334
                )}
                {renderAccordionItem(
                  "30-sec Social Media Video (Limited Offer)",
                  99,
                  0,
                  0
                )}
              </div>
            </IonAccordion>
            <IonAccordion value="aerial-packages">
              <IonItem slot="header" color="light">
                <IonLabel>Aerial Package Pricing</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem("1 Aerial (photo only)", 109, 159, 249)}
                {renderAccordionItem("5 Aerials (photo only)", 139, 219, 309)}
                {renderAccordionItem("10 Aerials (photo only)", 154, 254, 344)}
                {renderAccordionItem("20 Aerials (photo only)", 174, 314, 404)}

                {renderAccordionItem(
                  "1 Aerial (photo w/ video)",
                  254,
                  374,
                  524
                )}
                {renderAccordionItem(
                  "5 Aerials (photo w/ video)",
                  274,
                  424,
                  584
                )}
                {renderAccordionItem(
                  "10 Aerials (photo w/ video)",
                  284,
                  454,
                  614
                )}
                {renderAccordionItem(
                  "20 Aerials (photo w/ video)",
                  304,
                  514,
                  674
                )}
              </div>
            </IonAccordion>
            <IonAccordion value="addons">
              <IonItem slot="header" color="light">
                <IonLabel>Add-on Pricing</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem("Custom Flyer", 45, 135, 235)}
                {renderAccordionItem("Twilight Timeslot", 89, 169, 269)}
                {renderAccordionItem("Youtube Slideshow", 49, 139, 235)}
                {renderAccordionItem(
                  "Dollhouse VR (based on sqft) starts at $109",
                  0,
                  0,
                  0
                )}
                {renderAccordionItem("Dollhouse Floorplan", 34, 134, 189)}
              </div>
            </IonAccordion>
            <IonAccordion value="turnaround">
              <IonItem slot="header" color="light">
                <IonLabel>Turnaround Times</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem(
                  "Photo Next Day by 12:00p (standard)",
                  0,
                  0,
                  0
                )}
                {renderAccordionItem("Photo Same Day by 9:00p", 49, 79, 239)}
                {renderAccordionItem("Photo 4-Hours Turnaround", 89, 129, 269)}
                {renderAccordionItem(
                  "Video 2 Business Days by 12:00p (standard)",
                  0,
                  0,
                  0
                )}
                {renderAccordionItem(
                  "Video Next Business Day by 9:00p",
                  49,
                  99,
                  239
                )}
                {renderAccordionItem(
                  "Video Next Business Day by 12:00p",
                  89,
                  149,
                  269
                )}
                {renderAccordionItem(
                  "Dollhouse VR One Business Day (standard)",
                  0,
                  0,
                  0
                )}
                {renderAccordionItem(
                  "Dollhouse Floorplan 2 days after VR is uploaded",
                  0,
                  0,
                  0
                )}
              </div>
            </IonAccordion>
            <IonAccordion value="fees">
              <IonItem slot="header" color="light">
                <IonLabel>Fees</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                {renderAccordionItem(
                  "Properties over 4K sqft (photos)",
                  49,
                  49,
                  99
                )}
                {renderAccordionItem(
                  "Properties over 4K sqft (video)",
                  99,
                  99,
                  149
                )}
                {renderAccordionItem("Cancel/Reschedule", 99, 99, 99)}
                {renderAccordionItem(
                  "Cancel/Reschedule within 12h",
                  50,
                  50,
                  50
                )}
                {renderAccordionItem(
                  "Cancel/Reschedule within 24h",
                  25,
                  25,
                  25
                )}
              </div>
            </IonAccordion>
            <IonAccordion value="recommendations">
              <IonItem slot="header" color="light">
                <IonLabel>How Many Photos Should I Recommend?</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <p>
                  When upselling, use these guidelines to recommend the ideal
                  number of photos based on the property’s square footage:
                </p>
                <ul>
                  <li>
                    <strong>Under 1,500 sq ft:</strong> <br /> Recommend 15
                    Photos. Ideal for smaller homes, apartments, or condos.
                  </li>
                  <li>
                    <strong>1,500–2,999 sq ft:</strong> <br /> Recommend 25
                    Photos. Perfect for mid-sized homes with standard room
                    coverage.
                  </li>
                  <li>
                    <strong>3,000–4,499 sq ft:</strong> <br /> Recommend 36-42
                    Photos. Best for larger homes with multiple rooms, spacious
                    layouts, and unique features.
                  </li>
                  <li>
                    <strong> 5,000+ sq ft:</strong> <br /> Recommend 50 Photos.
                    Suitable for luxury homes, estates, or properties with
                    expansive layouts and extra details.
                  </li>
                </ul>
              </div>
            </IonAccordion>
            <IonAccordion value="script">
              <IonItem slot="header" color="light">
                <IonLabel>Upsell Script for Photographers</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <div className="upsell-script">
                  <p>
                    Based on my experience shooting homes like this, I’d
                    recommend a few additional options to really help showcase
                    the property at its best.
                  </p>

                  <p>
                    For a home of this size{" "}
                    <strong>([insert square footage])</strong>, upgrading to the{" "}
                    <strong>[next photo package]</strong> ensures we capture all
                    the important spaces, including any standout features like
                    the kitchen, living areas, or outdoor spaces.
                  </p>

                  <p>We can also include:</p>
                  <ul>
                    <li>
                      <strong>Aerial photos or videos:</strong> Highlight the
                      lot size and surrounding area – great for giving buyers a
                      sense of the full property.
                    </li>
                    <li>
                      <strong>Walkthrough video:</strong> Bring the home to life
                      with a full video tour.
                    </li>
                    <li>
                      <strong>Short social media clips:</strong> Perfect for
                      grabbing attention online and showcasing highlights.
                    </li>
                  </ul>

                  <p>
                    If you’re interested, I can make sure we include everything
                    that’ll make this listing shine. Let me know what works best
                    for you!
                  </p>
                </div>
              </div>
            </IonAccordion>
            <IonAccordion value="comms">
              <IonItem slot="header" color="light">
                <IonLabel>How to Contact Comms</IonLabel>
              </IonItem>
              <div className="ion-padding" slot="content">
                <div style={{ marginBottom: 25 }}>
                  <h4>
                    <IonIcon slot="icon-only" icon={callOutline} />{" "}
                    <a href="tel:972-533-6621">(972) 533-6621</a>
                  </h4>
                </div>
                <div style={{ marginBottom: 25 }}>
                  <h4>
                    <IonIcon slot="icon-only" icon={mailOutline} />{" "}
                    <a href="mailto:book@homesnappers.com">
                      book@homesnappers.com
                    </a>
                  </h4>
                </div>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Help;
