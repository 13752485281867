import moment from "moment";

/**
 * Adds leading zero to single digit numbers
 */
const addZero = (number: number): string => {
  return number < 10 ? `0${number}` : `${number}`;
};

/**
 * Converts 24-hour format to 12-hour format
 */
const convert12HrFmt = (hr: number): number => {
  if (hr === 0) return 12;
  if (hr > 12) return hr - 12;
  return hr;
};

/**
 * Converts ISO string to Date object
 */
export const convertISOStrToDate = (ISODateStr: string): Date => {
  const localDateStr =
    ISODateStr.split("T")[0] + " " + ISODateStr.split("T")[1].split(".")[0];
  return new Date(localDateStr);
};

/**
 * Converts Date object to time string in 12-hour format with AM/PM
 */
export const convertDateToTime = (date: Date): string => {
  const hour = convert12HrFmt(date.getHours());
  const minute = addZero(date.getMinutes());
  const session = date.getHours() < 12 ? "A" : "P";
  return `${hour}:${minute}${session}`;
};

/**
 * Converts Date object to ISO date string
 */
export const convertISODateString = (timestamp: Date): string => {
  const date = addZero(timestamp.getDate());
  const month = addZero(timestamp.getMonth() + 1);
  const year = timestamp.getFullYear();
  const hour = addZero(timestamp.getHours());
  const minute = addZero(timestamp.getMinutes());
  const second = addZero(timestamp.getSeconds());

  return `${year}-${month}-${date}T${hour}:${minute}:${second}.000Z`;
};

/**
 * Formats event date and time range
 */
export const eventDateFormat = (from: string, to: string): string => {
  return `${moment.utc(from).format("MMM D")}, ${moment
    .utc(from)
    .format("h:mm A")}-${moment.utc(to).format("h:mm A")}`;
};

/**
 * Formats event time range
 */
export const eventTimeFormat = (from: string, to: string): string => {
  return `${moment.utc(from).format("h:mm A")}-${moment
    .utc(to)
    .format("h:mm A")}`;
};

/**
 * Formats a date range in M/DD/YY format
 * Example: 3/15/23-3/21/23
 */
export const formatDateRange = (
  start: Date | string,
  end: Date | string
): string => {
  return `${moment(start).format("M/DD/YY")}-${moment(end).format("M/DD/YY")}`;
};

/**
 * Gets the start and end dates for a week
 * Returns dates in M/DD/YY format
 */
export const getWeekDateRange = (
  date: Date = new Date()
): { start: string; end: string } => {
  const start = moment(date).startOf("week").format("M/DD/YY");
  const end = moment(date).endOf("week").format("M/DD/YY");
  return { start, end };
};
