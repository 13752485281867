import moment from "moment";

export interface Shoot {
  _id: string;
  status: string;
  hsn: string;
  events: {
    start: string;
  };
  invoice: Array<{
    photographer_total_pay: number;
    products: Array<{
      _id: string;
      code: string;
      description: string;
      photographer_pay: number;
    }>;
  }>;
  booking_form?: {
    sqft?: number;
  };
  address?: {
    street: string;
    geometry?: {
      coordinates: [number, number];
    };
  };
  client_name_first: string;
  client_name_last: string;
}

export interface PaymentConfig {
  costMultiplierForDist: number;
  travelStipendAmount: number;
  prevBasePay: number;
  cutOffDate: moment.Moment;
  sqftCompensationEffectiveDate: moment.Moment;
  payEffectiveDate: string;
}

const DEFAULT_CONFIG: PaymentConfig = {
  costMultiplierForDist: 0.15,
  travelStipendAmount: 5,
  prevBasePay: 15,
  cutOffDate: moment("2023-03-03"),
  sqftCompensationEffectiveDate: moment("2024-09-20"),
  payEffectiveDate: "2022-04-04",
};

// Helper function to match original rounding behavior
const roundOffNumber = (num: number): number => {
  return Math.round(num * 100) / 100;
};

export const isPastEffectiveDate = (
  shoot: Shoot,
  effectiveDate: string = DEFAULT_CONFIG.payEffectiveDate
): boolean => {
  const effectiveDateValue = new Date(effectiveDate);
  const fetchedDateValue = new Date(shoot?.events?.start);
  return fetchedDateValue >= effectiveDateValue;
};

export const getBasePay = (
  invoiceDate: string,
  status: string,
  basePay: number,
  payMultiplier: number = 1,
  config: Partial<PaymentConfig> = {}
): number => {
  const { prevBasePay, cutOffDate } = { ...DEFAULT_CONFIG, ...config };
  const momentInvoiceDate = moment(invoiceDate);

  if (status === "Reschedule" || status === "Training") {
    return 0;
  }

  const pay = momentInvoiceDate.isBefore(cutOffDate)
    ? prevBasePay * payMultiplier
    : basePay * payMultiplier;

  return roundOffNumber(pay);
};

export const calculateShootTotal = (
  shoot: Shoot,
  distanceTraveled: number | undefined,
  basePay: number,
  payMultiplier: number = 1,
  config: Partial<PaymentConfig> = {}
): number => {
  const {
    costMultiplierForDist,
    travelStipendAmount,
    sqftCompensationEffectiveDate,
  } = { ...DEFAULT_CONFIG, ...config };

  // 1. Base Pay
  const basePayAmount = getBasePay(
    shoot.events.start,
    shoot.status,
    basePay,
    payMultiplier,
    config
  );

  // 2. Product Pay (from invoice)
  const productPay = shoot.invoice.reduce((total, inv) => {
    return total + (inv.photographer_total_pay || 0);
  }, 0);

  // 3. Distance Compensation - Match original rounding behavior
  let distanceCompensation = 0;
  if (distanceTraveled && isPastEffectiveDate(shoot)) {
    // Round the distance first, then calculate compensation
    const roundedDistance = Math.floor(distanceTraveled);
    distanceCompensation = roundOffNumber(
      roundedDistance * costMultiplierForDist
    );
  }

  // 4. Square Footage Compensation
  const sqftCompensation =
    moment(shoot.events.start).isSameOrAfter(sqftCompensationEffectiveDate) &&
    shoot.booking_form?.sqft &&
    shoot.booking_form.sqft >= 4000
      ? 15
      : 0;

  // 5. Travel Stipend (only for completed shoots)
  const travelStipend =
    isPastEffectiveDate(shoot) && shoot.status === "Completed"
      ? travelStipendAmount
      : 0;

  // Sum all components and round at the end
  const total =
    basePayAmount +
    productPay +
    distanceCompensation +
    sqftCompensation +
    travelStipend;

  return roundOffNumber(total);
};

export const groupShootsByDate = (shoots: Shoot[]): Record<string, Shoot[]> => {
  return shoots
    .filter(
      (shoot) =>
        shoot.status === "Completed" ||
        shoot.status === "Processing" ||
        shoot.status === "Reschedule" ||
        shoot.status === "Training" ||
        shoot.status === "Tentative" ||
        shoot.status === "Scheduled"
    )
    .sort((a, b) => {
      if (a?.events?.start && b?.events?.start) {
        return new Date(a.events.start) >= new Date(b.events.start) ? 1 : -1;
      }
      return 0;
    })
    .reduce((acc, shoot) => {
      const dateKey = moment(shoot.events.start).format("DD-MM-YYYY");
      return {
        ...acc,
        [dateKey]: (acc[dateKey] || []).concat(shoot),
      };
    }, {} as Record<string, Shoot[]>);
};

export const createAddressQueryList = (
  homeAddress: string,
  shoots: Shoot[]
): string[] => {
  return [
    homeAddress,
    ...shoots.map(
      (shoot) =>
        `${shoot.address?.geometry?.coordinates[1]},${shoot.address?.geometry?.coordinates[0]}`
    ),
  ];
};

export const calculateWeeklyTotal = (
  shoots: Shoot[],
  distanceTraveledMap: Record<string, number>,
  basePay: number,
  payMultiplier: number = 1,
  config: Partial<PaymentConfig> = {}
): number => {
  const total = shoots.reduce((sum, shoot) => {
    const shootTotal = calculateShootTotal(
      shoot,
      distanceTraveledMap[shoot._id],
      basePay,
      payMultiplier,
      config
    );
    return sum + shootTotal;
  }, 0);

  return roundOffNumber(total);
};
