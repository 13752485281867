import React, { useEffect, useState } from "react";
import "./LogoHeader.css";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import logoLightBg from "../assets/images/hs_logo_on_light.png";
import logoDarkBg from "../assets/images/hs_logo_on_dark.png";
import { personOutline, helpCircleOutline, heartOutline } from "ionicons/icons";

const LogoHeader: React.FC = () => {
  useEffect(() => {
    const systemDark = window.matchMedia("(prefers-color-scheme: dark)");
    setDarkModeLogo(systemDark.matches);
  }, []);

  const [darkModeLogo, setDarkModeLogo] = useState(false);

  // const renderLogo = (isDarkMode: boolean) => {
  //   return <>{isDarkMode ? <img src={logo} alt="Home Snappers" /> : "Logo"}</>;
  // };

  return (
    <div>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton routerLink={"/help"}>
              <IonIcon slot="icon-only" icon={helpCircleOutline} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <div className="logo-wrapper">
              <div className="logo">
                {!darkModeLogo ? (
                  <img src={logoLightBg} alt="Home Snappers" />
                ) : (
                  <img src={logoDarkBg} alt="Home Snappers" />
                )}
              </div>
            </div>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton routerLink={"/favorites"}>
              <IonIcon slot="icon-only" icon={heartOutline} />
            </IonButton>
            <IonButton routerLink={"/profile"}>
              <IonIcon slot="icon-only" icon={personOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    </div>
  );
};

export default LogoHeader;
